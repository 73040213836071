import React, { useContext, useState } from 'react';
import Message from './Message';
import "../../css/MessageList.css";
import { formatAMPM, markAsRead, getMessageContentType } from '../../utils';
import { GameStateContext } from '../../context/context';

export default function MessageList(props) {
    const [selectedMessage, setSelectedMessage] = useState(undefined);
    const { setLastUpdated } = useContext(GameStateContext);

    const { placeholder, messageList = [], sender } = props || {};

    const selectMessage = (message) => {
        markAsRead(message);
        setSelectedMessage(message);
        setLastUpdated(Date.now());
    }

    if (selectedMessage?.message) {
        // console.log("selectedMessage", selectedMessage);
        // console.log("Message", getMessageContentType(selectedMessage));
        return (<Message {...(selectedMessage || {})} messageType={sender.toLowerCase() === "you" ? "sentbox": "inbox"} contentType={getMessageContentType(selectedMessage)} onClose={() => setSelectedMessage(null)
        } />)
    }
    else if (messageList.length > 0) {
        return (<div className={"message-list-container"}>
            {messageList.map((message, index) => {return (

                    <div key={"message-" + index} className={`message-card ${message?.unread ? "message-card-unread" : ""}`} onClick={() => selectMessage(message)}>
                        <span className={"message-card-name"}>{message.sender}</span>
                        <span className={"message-card-content"}>{message.message}</span>
                        <span className={"message-card-time"}>{formatAMPM(new Date(message.time))} </span>
                    </div>
                )
            })}
        </div>)
    }
    return (<p className={"content-placeholder bg-dark-gray br-1rem d-flex justify-content-center align-items-center w-100"}>{placeholder}</p>)
}