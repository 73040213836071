import React from 'react';
import { AuthProvider } from 'oidc-react';
import Desktop from './components/Desktop';
import 'bootstrap/dist/css/bootstrap.min.css';
import { oidcConfig } from './oidc';
import { GameStateProvider } from './context/provider'

let oidc = oidcConfig[process.env.REACT_APP_ENVIRONMENT]

function App() {
  return (  
    <AuthProvider autoSignIn={false} {...oidc}>
      <GameStateProvider>
          <Desktop />
      </GameStateProvider>
    </AuthProvider>
  ); 
}

export default App;