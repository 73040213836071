import React, { useContext, useState } from 'react';
import { GameStateContext } from '../../context/context';
import { assets } from "../../assets/config";
import "../../css/Drawer.css"

const { common: { haknova_alert_icon,
    haknova_window_close_icon,
    haknova_open_arrow_icon,
    haknova_collapse_arrow_icon,
    haknova_hamburger_mobile },
    baseUrl } = assets

export default function Drawer(props) {
    const [drawerState, toggleDrawer] = useState(false);
    const { newMessageAvailable, gameStateSuccess, resetState } = useContext(GameStateContext);

    const { mailboxContent, setMailboxContent } = props;

    const renderAlert = () => (newMessageAvailable ? <img
        src={baseUrl + haknova_alert_icon}
        alt={baseUrl + haknova_alert_icon}
        className={'new-message-alert'} /> : null);

    let mobileHeading = "";
    if (mailboxContent === "compose")
        mobileHeading = "+ Compose";
    else if (mailboxContent === "inbox")
        mobileHeading = "Inbox";
    else if (mailboxContent === "sent")
        mobileHeading = "Sent";

    return (<div className={`drawer-container ${drawerState ? "drawer-container-expanded" : ""}`}>
        <img alt="drawer toggle" className={"drawer-toggler"} src={baseUrl + (drawerState ? haknova_collapse_arrow_icon : haknova_open_arrow_icon)} onClick={() => toggleDrawer(state => !state)} />
        <img alt="hamburger icon" className={"hamburger-icon"} src={baseUrl + haknova_hamburger_mobile} onClick={() => toggleDrawer(state => !state)} />
        <span className={"mobile-heading"}>
            {mobileHeading}
        </span>
        {drawerState ? <div className={"drawer-menu"}  >
            <div className={"drawer-mobile-header"}>
                <img
                    src={baseUrl + haknova_window_close_icon}
                    alt={baseUrl + haknova_window_close_icon}
                    className={'drawer-close-button'}
                    onClick={() => toggleDrawer(state => !state)}
                /></div>
            <div className={"menu-container d-flex justify-content-center flex-column align-items-center"} >
                
                <button onClick={() => { (window.innerWidth < '1024'&& toggleDrawer(false)); setMailboxContent("compose") }} disabled={gameStateSuccess} className={`${gameStateSuccess} drawer-menu-item ${mailboxContent === "compose" ? "drawer-menu-item-active" : ""}`}>+ Compose</button>
                
                <div className={"menu-divider"}></div>
                <button onClick={() => { (window.innerWidth < '1024'&& toggleDrawer(false)); setMailboxContent("inbox") }} className={`drawer-menu-item ${mailboxContent === "inbox" ? "drawer-menu-item-active" : ""}`}>{newMessageAvailable ? <>&nbsp;&nbsp;&nbsp;&nbsp;</> : ""}Inbox{renderAlert()}</button>
                <div className={"menu-divider"}></div>
                <button onClick={() => { (window.innerWidth < '1024'&& toggleDrawer(false)); setMailboxContent("sent") }} className={`drawer-menu-item ${mailboxContent === "sent" ? "drawer-menu-item-active" : ""}`}>Sent</button>
            </div>
            <button className={"gray-btn"} onClick={props?.logout}>LOG OUT</button>
            {/* <button className={"gray-btn"} onClick={resetState}>Reset</button> */}
        </div> : renderAlert()}
    </div>)
}