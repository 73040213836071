import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Compose from "./Compose";
import Drawer from "./Drawer";
import Inbox from "./Inbox";
import "../../css/SecretContactForm.css"
import Sentbox from "./Sentbox";
import { GameStateContext } from "../../context/context";

export default function SecretContactForm(props) {
    const [mailboxContent, setMailboxContent] = useState("compose");
    const { gameStateSuccess } = useContext(GameStateContext);
    const prevGameState = useRef(gameStateSuccess);

    useEffect(() => {
        //console.log("gameStateSuccess ::", gameStateSuccess)
        if (gameStateSuccess === true)
            setMailboxContent("inbox");
        prevGameState.current = gameStateSuccess;
    }, [gameStateSuccess]);

    const content = useMemo(() => {
        if (mailboxContent === "compose") {
            return (<Compose />);
        }
        else if (mailboxContent === "inbox") {
            return (<Inbox />);
        }
        else if (mailboxContent === "sent") {
            return (<Sentbox />);
        }
    }, [mailboxContent]);

    return (<div className={"contact-form-container"}>
        <Drawer logout={props?.logout} mailboxContent={mailboxContent} setMailboxContent={setMailboxContent} />
        <div className={"mailbox"}>
            {content}
        </div>
    </div>)
}