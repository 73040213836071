import React from "react";
import { Media } from "./Media";
import { Col } from "react-bootstrap";
import { assets } from "../../../assets/config"
const { home: { haknova_ocean_background, haknova_ocean_rescue } } = assets

const Home = (props) => {
  return (<div id={props.id}>
    <Media url={haknova_ocean_background} className={"w-100 home-banner"}>
      <div className={"text-white text-center mx-5 home-banner-text"}>OFFERING A LIFELINE TO THOSE LOST AT SEA</div>
    </Media>
    <div className={"d-flex flex-wrap"}>
      <Col md={7} className={"rescue-text d-flex align-items-center justify-content-center"}>
        <Col lg={8} md={10} xs={11}>
          Globally, hundreds of thousands of people die by drowning every year, more than half of them children. Many drownings occur in open water, where search and rescue operations can face significant challenges at substantial expense. In too many instances, the bodies of those lost at sea are never recovered, leaving the families and loved ones of victims with a grief that can never truly be put to rest.
          <br />
          <br />
          Tragically, the majority of drownings are preventable. It is within our reach to drastically reduce the number of people lost at sea and to offer solace for those left behind.
        </Col>
      </Col>
      <Col md={5} xs={12} className={"p-0"}>
        <Media url={haknova_ocean_rescue} className={"w-100 haknova-ocean-rescue"} />
      </Col>
    </div>

  </div>);
}



export default Home