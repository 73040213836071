import React, { useState } from "react";
import { assets } from "../../assets/config";
import "../../css/login.css"

const { baseUrl,
    login: { haknova_cosmic_backround,
        haknova_supernova_logo_color }
} = assets;

const LOGIN_PASSWORD = process.env.REACT_APP_TEXT_ENTRY_PASS;

export default function LoginWrapper(props) {
    const { loggedIn, setLoggedIn } = props;

    if (loggedIn) {
        return props?.children
    }
    return (<LoginPage loginSuccess={() => setLoggedIn(true)} />)
}

function LoginPage(props) {
    const [password, setPassword] = useState("");
    const [errorState, setErrorState] = useState(false);
    const checkPassword = () => {
        if (password === LOGIN_PASSWORD) {
            props?.loginSuccess();
        }
        else {
            setErrorState(true);
        }
    }
    return (<div style={{ backgroundImage: `url(${baseUrl + haknova_cosmic_backround})` }} className={"login-container"}>
        <img alt="Super Nova Logo" src={baseUrl + haknova_supernova_logo_color} className={"haknova-supernova-logo"}></img>
        <h1 className={"login-message"}>
            Fill out thiS form to gab with Gunny Graham!
            If you have a meSSage for Supernova,
            he’ll be Sure to paSS it on!
        </h1>
        <div className={"login-credentials"}>
            <div className={"login-credentials-title"}>Tell us about yourself!</div>
            <input placeholder={"Your name"} className={"cred-input"} aria-label="Your Name" />
            <input placeholder={"Password"}
                aria-label="Password"
                type={"password"}
                className={"cred-input"}
                value={password}
                onChange={e => {
                    errorState && setErrorState(false);
                    setPassword(e.target.value);
                }} />
            {errorState ? <span className={"invalid-text"}>
                Invalid password
            </span> : null}
            <button onClick={checkPassword} className={"enter-btn"}>ENTER</button>
        </div>
    </div>)
}