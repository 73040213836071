export const assets = {
    baseUrl: window.location.origin,
    home: {
        haknova_ocean_background: '/assets/haknova-ocean-background.png',
        haknova_halo_graham_logo: '/assets/haknova-halo-graham-logo.svg',
        haknova_ocean_rescue: '/assets/haknova-ocean-rescue.png',
        haknova_hamburger_web: "/assets/haknova-white-menu-button-mobile.svg"
    },
    common: {
        haknova_ocean_banner: '/assets/haknova-ocean-banner.png',
        haknova_window_close_icon: '/assets/haknova-window-close-icon.svg',
        haknova_alert_icon: "/assets/haknova-alert-icon.svg",
        haknova_open_arrow_icon: "/assets/haknova-open-arrow-icon.svg",
        haknova_collapse_arrow_icon: "/assets/haknova-collapse-arrow-icon.svg",
        haknova_hamburger_mobile: "/assets/haknova-hamburger-mobile.svg",
        HAKNOVAVID_News_Report_082421_WE_FINAL: "https://embed-ssl.wistia.com/deliveries/15bd3b1667c70b2c0e7b407086431aa4.mp4",
        haknova_fantasy_ending: "/assets/HAKNOVAPP024_Fantasy_Ending_Comic_Page_051321_ASK_Final.jpg",
        haknova_truth_ending: "/assets/HAKNOVAPP025_Truth_Ending_Comic_Page_051821_ASK_Final.jpg",
        haknova_icon_attachment: "/assets/haknova-icon-attachment.png"
    },
    about: {
        haknova_ruth_photo: "/assets/haknova-ruth-photo.png",
        haknova_ruth_signature_photo: "/assets/haknova-ruth-signature-photo.png"
    },
    login: {
        haknova_cosmic_backround: "/assets/login/haknova-cosmic-backround.png",
        haknova_supernova_logo_color: "/assets/login/haknova-supernova-logo-color.svg"
    },
    landing: {
        haknova_emblem_logo: "/assets/haknova-emblem-logo.svg"
    }
}
