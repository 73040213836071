import React, { useContext, useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "../../css/Message.css";
import { assets } from "../../assets/config";
import { GameStateContext } from '../../context/context';
import { sendNeighborEmail, getLocalPlayerType } from '../../utils';
const { common: { haknova_window_close_icon, HAKNOVAVID_News_Report_082421_WE_FINAL, haknova_icon_attachment, haknova_fantasy_ending, haknova_truth_ending }, baseUrl } = assets

export default function Message(props) {
    const [displayVideo, launchVideo] = useState(false);
    const [displayComic, launchComic] = useState(false);
    const { message, onClose, messageType, contentType } = props || {};
    const [neighborEmailSent, neighborEmail] = useState(false);
    const { setLastUpdated, setNewMessageAvailable } = useContext(GameStateContext);


    useEffect(() => {

    if (displayVideo === true && neighborEmailSent === false) {  
        sendNeighborEmail();
        setNewMessageAvailable(true);
        setLastUpdated(Date.now());
        neighborEmail(true);
    }
    
    },[displayVideo,neighborEmailSent, setLastUpdated, setNewMessageAvailable])

    return (<div className={"message-container"}>
        <div className={"message-header"}>
            <img
                src={baseUrl + haknova_window_close_icon}
                alt={baseUrl + haknova_window_close_icon}
                className={`message-close-button ${(contentType === "finalMessage" && !displayVideo && messageType !== "sentbox") ? "d-none" : ""}`}
                onClick={onClose} />
        </div>
        <div className={`message-body ${displayVideo ? "p-0 bg-dark" : ""}`}>
            {displayVideo ? <video className={"w-100 h-100"} controls>
                <source src={HAKNOVAVID_News_Report_082421_WE_FINAL} type="video/mp4" />
            </video> : null }
            
            {(!displayVideo && !displayComic) ? message : null}

            {(contentType === "finalMessage" && !displayVideo) ?
                <button className={"gray-btn"} onClick={() => launchVideo(true)}>DONE</button>
                : null}
            
            {displayComic ? 
            <TransformWrapper
                initialScale={0.37}
                initialPositionX={0}
                initialPositionY={0}
                doubleClickStep={0.7}
                minScale={0.37}
                wheel={{ disabled: true }}
                pinch={{ disabled: true }}
            >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent>
                {(getLocalPlayerType() === "fan") ? <img src={baseUrl + haknova_fantasy_ending} alt="Ending " /> : <img src={baseUrl + haknova_truth_ending} alt="Ending" />}}
                </TransformComponent>
                <div className="tools">
                  <button onClick={() => zoomIn()}>+</button>
                  <button onClick={() => zoomOut()}>-</button>
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>
            : null}

            {(contentType === "neighbor" && !displayComic) ?
                <button className={"attachment-button"} onClick={() => launchComic(true)}>ATTACHMENT
                    <img src={baseUrl + haknova_icon_attachment} alt={baseUrl + haknova_icon_attachment} className={"attachment-icon"} />
                </button>
                : null}
            
        </div>
    </div>)
}