
const game = "sn";

export const qna = (question, episode = "01", cta = "1") => {
    return fetch(`https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/9e92affc-1fb2-46c1-8ed6-d394d3ced8f2/generateAnswer`, {
        method: 'POST',
        body: JSON.stringify({
            "question": question,
            "top": 1,
            "scoreThreshold": 50,
            "strictFilters": [{ "name": "episode", "value": `${game}${episode}.${cta}` }]
        }),
        headers: {
            Authorization: `EndpointKey 69ccf40a-ac15-4259-841d-97e4ad9f382d`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
};


export const saveToLocalInbox = (message, time, sender) => {
    let inbox = [];
    try {
        const val = localStorage.getItem("inbox");
        inbox = (val && JSON.parse(val)) || []
    }
    catch (ex) {
        console.error(ex);
        inbox = []
    }

    inbox.unshift({ message, time, unread: true, sender: sender })
    localStorage.setItem("inbox", JSON.stringify(inbox));
    localStorage.setItem("lastUpdated", Date.now().toString());
}

export const saveToLocalSentbox = (message, time) => {
    let sentbox = [];
    try {
        const val = localStorage.getItem("sentbox");
        sentbox = (val && JSON.parse(val)) || []
    }
    catch (ex) {
        console.error(ex);
        sentbox = []
    }

    sentbox.unshift({ message, time })
    localStorage.setItem("sentbox", JSON.stringify(sentbox));
    localStorage.setItem("lastUpdated", Date.now().toString());
}

export const setLocalGameState = (state = {}) => {
    try {
        localStorage.setItem("gameState", JSON.stringify(state));

    }
    catch (e) {
        console.log("error : ", e)
    }
}

export const getLocalGameState = (email) => {
    let state = {};
    try {

        state = JSON.parse(localStorage.getItem("gameState"));
        return {};
    }
    catch (e) {
        console.log("error : ", e)
    }
    finally {
        return state;
    }
}

export const getLocalInbox = () => {
    let inbox = [];
    try {
        const val = localStorage.getItem("inbox");
        inbox = (val && JSON.parse(val)) || []
    }
    catch (ex) {
        console.error(ex);
        inbox = []
    }
    return inbox;
}

export const getLastUpdated = () => {
    return localStorage.getItem("lastUpdated");
}

export const getLocalSentbox = () => {
    let sentbox = [];
    try {
        const val = localStorage.getItem("sentbox");
        sentbox = (val && JSON.parse(val)) || []
    }
    catch (ex) {
        console.error(ex);
        sentbox = []
    }
    return sentbox;
}

export const setLocalPlayerType = (state = {}) => {
    try {
        localStorage.setItem("playerType", JSON.stringify(state));

    }
    catch (e) {
        console.log("error : ", e)
    }
}

export const getLocalPlayerType = (email) => {
    let state = {};
    try {

        state = JSON.parse(localStorage.getItem("playerType"));
        return {};
    }
    catch (e) {
        console.log("error : ", e)
    }
    finally {
        return state;
    }
}

export const resetLocalMailbox = () => {
    localStorage.removeItem("inbox");
    localStorage.removeItem("sentbox");
    localStorage.removeItem("gameState");
    localStorage.removeItem("lastUpdated")
}

export const markAsRead = (message) => {
    const inbox = getLocalInbox().map(msg => {
        if (msg.time === message.time)
            msg.unread = false
        return msg;
    });
    localStorage.setItem("inbox", JSON.stringify(inbox));
}

export const replaceTokens = (text) => {
    return text.replace("{{Correct}}", "").replace("{{Incorrect}}", "").replace("{", "").replace("}", "").replace(".mp3", "");
}

export const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const sendNeighborEmail = () => {
    const friend_message = `Hello,

We know you were working with Gunny, and he wanted us to reach out. Discovering he had someone like you makes us so happy. He was such a sweet man, and the best neighbor we’ve ever had.
            
Gunny wanted to make sure you got the attached. He said you’d know what it means.
            
As Gunny would say: Let the light of truth shine brightly,
            
– Rick & Bruce`;

    saveToLocalInbox(friend_message, Date.now(), 'Gunny’s Neighbor');
}

export const getMessageContentType = (message) => {
    if (message?.message.startsWith("My trusted friend") || message?.message.startsWith("My trusted fan")) {
        return "finalMessage";
    }
    else if (message?.message.startsWith("Hello,")) {
        return "neighbor";
    }
    return "other";
}