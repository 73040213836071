import React, { useState } from 'react';
import Div100vh from 'react-div-100vh';
import Website from './Website'
import LoginWrapper from "./Login/Login"
import SecretContactForm from './SecretContactForm/SecretContactForm';

const SUPER_NOVA_WEB_URL = process.env.REACT_APP_SUPER_NOVA_WEB_URL || "halograhamfoundation";
let LOADED_WEB_URL = false;

if (window.location.href.indexOf(SUPER_NOVA_WEB_URL) !== -1) {
  console.log(window.location.href.indexOf(SUPER_NOVA_WEB_URL));
  
  LOADED_WEB_URL = true;
  document.getElementsByTagName("title")[0].innerText = "Halo Graham Foundation";
  document.getElementsByName("web-icon")[0].href = `${window.location.origin}/favicon.ico`;
}

const Desktop = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  
  if (!LOADED_WEB_URL) {
      return (<Div100vh dontresize="true" className="vh-100 vw-100 hak-desktop" >
        <LoginWrapper loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
          <SecretContactForm logout={() => setLoggedIn(false)} />
        </LoginWrapper>
      </Div100vh>)
  }
  else
    return (<Website />)
};

export default Desktop;