import React, { useContext, useEffect, useState } from 'react'
import { GameStateContext } from '../../context/context';
import { getLocalSentbox } from '../../utils';
import MessageList from './MessageList';

export default function Sentbox(props) {
    const { lastUpdated } = useContext(GameStateContext);
    const [sentbox, setSentboxItems] = useState([]);
    useEffect(() => {
        setSentboxItems(getLocalSentbox());
    }, [lastUpdated]);

    return (<MessageList placeholder={"No sent messages."} sender={"You"} messageList={sentbox} />)
}