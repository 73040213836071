import Div100vh from 'react-div-100vh';
import OurMission from './components/OurMission';
import Home from './components/Home';
import About from './components/About';
import "./css/index.css"
import { Media } from "./components/Media";
import { assets } from "../../assets/config"
import { Col, Container } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
const { home: { haknova_halo_graham_logo, haknova_hamburger_web }, common: { haknova_window_close_icon }, baseUrl } = assets

let homeOffset, ourMissionOffset, aboutOffset;
let homeButton, ourMissionButton, aboutButton;

document.onreadystatechange = () => {
    const bodyRect = document.body.getBoundingClientRect();
    
    homeOffset = document.getElementById("home")?.getBoundingClientRect().top - bodyRect.top;
    ourMissionOffset = document.getElementById("our-mission")?.getBoundingClientRect().top - bodyRect.top;
    aboutOffset = document.getElementById("about")?.getBoundingClientRect().top - bodyRect.top;

    homeButton = document.getElementById("home-button");
    ourMissionButton = document.getElementById("our-mission-button");
    aboutButton = document.getElementById("about-button");
}

const Header = (props) => {

    const { toggleMobileMenu } = props;

    useEffect(() => {
        const handleOnScroll = () => setActivePage(document.documentElement.scrollTop);
        window.addEventListener("scroll", handleOnScroll);
        return () => window.removeEventListener("scroll", handleOnScroll);
    }, [])

    const setActivePage = (pageOffset) => {
        requestAnimationFrame(() => {
            if (pageOffset >= aboutOffset) {
                aboutButton.classList.add("active");
                ourMissionButton.classList.remove("active");
                homeButton.classList.remove("active");
            }
            else if (pageOffset >= ourMissionOffset) {
                aboutButton.classList.remove("active");
                ourMissionButton.classList.add("active");
                homeButton.classList.remove("active");
            }
            else if (pageOffset >= homeOffset) {
                aboutButton.classList.remove("active");
                ourMissionButton.classList.remove("active");
                homeButton.classList.add("active");
            }
        })
    }

    const handleOnClick = useCallback((page) => {
        let offset;
        if (page === "home") {
            offset = homeOffset;
        }
        else if (page === "our-mission") {
            offset = ourMissionOffset;
        }
        else if (page === "about") {
            offset = aboutOffset;
        }
        window.scrollTo({ top: offset, behavior: "smooth" });
    }, []);

    return (<Div100vh className={"header d-flex w-100 justify-content-between align-items-center p-4"}>
        <Media url={haknova_halo_graham_logo} className={"header-logo justify-content-between align-items-center"} />
        <nav className="navbar navbar-expand-md navbar-light h5 font-weight-bold">
            <div className="container-fluid">
                <button className="navbar-toggler border-0" type="button"  onClick={() => toggleMobileMenu(enabled => !enabled)}>
                    <img src={baseUrl + haknova_hamburger_web} alt={baseUrl + haknova_hamburger_web}  />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <button className="nav-link px-4 active bg-transparent border-0" id="home-button" aria-current="page" onClick={() => handleOnClick("home")}>HOME</button>
                        <button className="nav-link px-4 bg-transparent border-0" id="our-mission-button" onClick={() => handleOnClick("our-mission")}>OUR MISSION</button>
                        <button className="nav-link px-4 bg-transparent border-0" id="about-button" onClick={() => handleOnClick("about")}>ABOUT</button>
                    </div>
                </div>
            </div>
        </nav>
    </Div100vh>)
}

const MobileMenu = (props) => {
    const { toggleMobileMenu, enabled } = props;
    return enabled ? (
        <div className={`vw-100 vh-100 mobile-menu ${props?.className}`}>
            <div className={"close-button"}>
                <a href="#home" onClick={() => toggleMobileMenu(enabled => !enabled)} >
                    <img src={baseUrl + haknova_window_close_icon} alt={baseUrl + haknova_window_close_icon} className={'close-button-icon'} />
                </a>
            </div>
            <div className="navbar-nav d-flex justify-content-center align-items-center vh-100">
                <a className="nav-link px-4 active" href="#home" onClick={() => toggleMobileMenu(enabled => !enabled)}>HOME</a>
                <Container fluid className={"d-flex justify-content-center my-4"}>
                    <Col md={10} xs={11} className={"divider bg-white"} />
                </Container>
                <a className="nav-link px-4 active" href="#our-mission" onClick={() => toggleMobileMenu(enabled => !enabled)}>OUR MISSION</a>
                <Container fluid className={"d-flex justify-content-center my-4"}>
                    <Col md={10} xs={11} className={"divider bg-white"} />
                </Container>
                <a className="nav-link px-4 active" href="#about" onClick={() => toggleMobileMenu(enabled => !enabled)}>ABOUT</a>
            </div>
        </div>) : null
}

const Website = () => {
    const [mobileMenuEnabled, toggleMobileMenu] = useState(false);

    return (<div dontresize="true" >
        <MobileMenu enabled={mobileMenuEnabled} toggleMobileMenu={toggleMobileMenu} className={"d-md-none"} />
        <Header toggleMobileMenu={toggleMobileMenu} />
        <>
            <Home id={"home"} />
            <OurMission id={"our-mission"} />
            <About id={"about"} />
        </>
        <footer />
    </div>)
};

export default Website;