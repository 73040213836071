import React, { useEffect, useState } from 'react';
import { GameStateContext } from './context';
import { getLocalGameState, resetLocalMailbox, saveToLocalInbox, saveToLocalSentbox, setLocalGameState , setLocalPlayerType } from '../utils';

export function GameStateProvider(props) {
    const [lastUpdated, setLastUpdated] = useState(Date.now());
    const [newMessageAvailable, setNewMessageAvailable] = useState(false);
    const [gameStateSuccess, setGameStateSuccess] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let unlocked = [];

            if (unlocked?.length > 0) {
                console.log("Unlocked :: ", unlocked);
                resetLocalMailbox();
                saveToLocalInbox(unlocked[0].inbox.message, unlocked[0].inbox.time, 'Gunny Graham');
                saveToLocalSentbox(unlocked[0].sentbox.message, unlocked[0].sentbox.time);
                setLastUpdated(Date.now());
                setGameStateSuccess(true)
            }
            else {
                const localState = getLocalGameState('supernova');
                if (localState?.gameStateSuccess) {
                    setGameStateSuccess(true)
                }
                else if (localState) {
                    resetLocalMailbox();
                }
            }
        }

        fetchData();

    });

    const postState = (unlocked) => {
        
        try {
            (async () => {
                if (unlocked?.length > 0) {
                    //console.log("Unlocked :: ", unlocked);
                    if ((unlocked[0]?.inbox?.message || "").startsWith("My trusted friend")) {
                        // console.log("FRIEND");
                        setGameStateSuccess(true);
                        setLocalGameState({
                            gameStateSuccess: true
                        });
                        setLocalPlayerType("friend");
                    } else if ((unlocked[0]?.inbox?.message || "").startsWith("My trusted fan")) {
                        // console.log("FAN");
                        setGameStateSuccess(true);
                        setLocalGameState({
                            gameStateSuccess: true
                        });
                        setLocalPlayerType("fan");
                    }
                }
            })();
        }
        catch (ex) {
            console.error(ex);
        }
    }

    return (
        <GameStateContext.Provider
            value={{
                gameStateSuccess,
                addState: (newState) => {
                    postState([newState]);

                },
                resetState: () => {
                    postState([]);
                    resetLocalMailbox();
                    setLastUpdated(Date.now());
                    setGameStateSuccess(false);
                    setLocalGameState({
                        gameStateSuccess: false
                    })
                    setLocalPlayerType("");
                },
                lastUpdated,
                setLastUpdated,
                newMessageAvailable,
                setNewMessageAvailable
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}