import React, { useContext, useState } from 'react';
import { GameStateContext } from '../../context/context';
import { qna, replaceTokens, saveToLocalInbox, saveToLocalSentbox } from '../../utils';

const no_good_match = "no good match found in kb.";
const incorrect_response = `My friend,

I’m certain you must have made a mistake, as I can’t make heads or tails of what you’re trying to tell me! 

Please take the time you need to collect yourself and then try me again: What answer do you have for me with regard to my sister Halo?

Eagerly awaiting word from you,

Gunny`;

export default function Compose(props) {
    const [mailContent, setMailContent] = useState("");
    const [displayConfirmDailog, setConfirmDailogState] = useState(false);
    const [sendingMessage, displaySendingMessage] = useState(false);
    const { addState, setLastUpdated, setNewMessageAvailable } = useContext(GameStateContext);

    const handleSend = async () => {
        //Logic to handle send mail
        const requestTime = Date.now();
        try {
            setConfirmDailogState(false);
            displaySendingMessage(true);
            setLastUpdated(Date.now());

            let response = await qna(mailContent);
            const data = await response.json();
            const responseTime = Date.now();
            const messageResponse = replaceTokens((data?.answers && data?.answers[0]?.answer) || "");
            if (messageResponse.toLowerCase() === no_good_match) {
                saveToLocalInbox(incorrect_response, responseTime, 'Gunny Graham');
                addState({ inbox: { message: incorrect_response, time: responseTime }, sentbox: { message: mailContent, time: requestTime } })
            }
            else {
                //resetLocalMailbox();
                saveToLocalInbox(messageResponse, responseTime, 'Gunny Graham');
                addState({ inbox: { message: messageResponse, time: responseTime }, sentbox: { message: mailContent, time: requestTime } })
            }
            saveToLocalSentbox(mailContent, requestTime);
            setNewMessageAvailable(true);
            setLastUpdated(Date.now());
            setMailContent("");
            displaySendingMessage(false);
        } catch (e) {
            console.log(e);
            displaySendingMessage(false);
        }
    }

    return (
        <div className={"compose-container"}>
            <p className={"content-placeholder"}>
                This area is restricted to those I trust.
                <br />
                If you’ve made it this far, then you have the answer.
            </p>
            <div className={`dailog-wrapper ${!displayConfirmDailog && "d-none"}`}>
                <div className={"confirm-dailog"}>
                    <span>
                        Are you sure you want to send this message? You won’t get another chance to talk to Gunny.
                    </span>
                    <div className={"confirm-dailog-footer"}>
                        <button className={"gray-btn"} onClick={handleSend}>YES</button>
                        <button className={"gray-btn"} onClick={() => setConfirmDailogState(false)}>NO</button>
                    </div>
                </div>
            </div>
            <div className={`dailog-wrapper ${!sendingMessage ? "d-none" : ""}`}>
                <div className={"sending-message"}>
                    <span>
                        Sending...
                    </span>
                </div>
            </div>
            <textarea
                aria-label="Type your message here"
                rows={"5"}
                type={"text"}
                maxLength={80}
                value={mailContent}
                onChange={e => setMailContent(e.target.value)}
                className={"compose-text-area"}
                placeholder={"Type your message here"} />
            <div className={"compose-footer"}>
                <p className={`${mailContent.length === 80 ? "text-danger" : ""}`}>{mailContent.length}/80</p>
                <button className={"gray-btn"} onClick={() => mailContent && setConfirmDailogState(true)}>SEND</button>
            </div>
        </div>)
}
