import React, { useContext, useEffect, useState } from 'react';
import { GameStateContext } from '../../context/context';
import { getLocalInbox } from '../../utils';
import MessageList from './MessageList';

export default function Inbox(props) {
    const { lastUpdated, setNewMessageAvailable } = useContext(GameStateContext);
    const [inbox, setInbox] = useState([]);
    useEffect(() => {
        const localInbox = getLocalInbox()
        setInbox(localInbox);
        let allRead = true;
        for (let msg of localInbox) {
            allRead = allRead && !msg.unread
        }
        setNewMessageAvailable(!allRead);
    }, [lastUpdated, setNewMessageAvailable]);

    return (<MessageList placeholder={"Nothing in your inbox."} sender={"Gunny Graham"} messageList={inbox} />)
}