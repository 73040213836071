import React from "react";
import { Media } from "./Media"

import { assets } from "../../../assets/config"
import { Col, Container } from "react-bootstrap";
const { common: { haknova_ocean_banner } } = assets

const SectionList = [
  {
    heading : "Prevention",
    content : "Our safety training guides are used in lifeguard programs all over the world. Education about maritime safety and proper swimming supervision has helped reduce the number of casualties each year."
  },
  {
    heading : "Rescue & Recovery",
    content : "We have helped coordinate dozens of urgent rescue operations in the United States and funded many recovery efforts where other agencies have abandoned all hope. We have also assisted numerous international maritime accident response teams. We regularly purchase state-of-the-art technology for under-resourced search and rescue teams, and train divers, search dogs, and lifeguards on new lifesaving techniques."
  },
  {
    heading : "Support",
    content : "We have connected thousands of families of those lost at sea to grief counselors and offer training to mental health professionals in high-risk seaside communities. We help survivors navigate this painful experience, including legal counsel for death in absentia proceedings and support for memorial services."
  },
  {
    heading : "Advocacy",
    content : "We are fighting every day for more stringent safety regulations and improved shipboard equipment to prevent senseless loss of life due to lack of oversight. Our experts consult with industries from deep-sea fisheries to commercial cruise lines to ensure the safety and security of oceangoing vessels."
  }
]

const Section = (props) => {
  return  (<div className={"section d-flex justify-content-center"}>
    <Col md={10} xs={11} className={"justify-content-center"}>
      <div className={"text-center section-heading pb-2"}>{props?.heading || ""}</div>
      <div>{props?.content || ""}</div>
    </Col>
  </div>)
}

const OurMission = (props) => {
  return (<div className={"pb-5"}>
    <div className={"anchor-margin"} id={props.id} />
    <Media url={haknova_ocean_banner} className={"w-100 ocean-banner"}>
      <div className={"text-white text-center mx-5 ocean-banner-text"}>OUR MISSION</div>
    </Media>
    <Container fluid className={"d-flex justify-content-center"}>
      <Col md={10} xs={11} className={"mission-text"}>
        No one should have to wait for help in a life-or-death situation or endure the painful uncertainty whether a loved one’s remains will ever be recovered.
        <br />
        <br />
        The Halo Graham Foundation funds rescue and recovery operations for those missing at sea, and offers support to individuals affected by open water drownings. Through our safety education and regulatory advocacy efforts, we aim to decrease preventable tragedies.
      </Col>
    </Container>
    <Container fluid className={"d-flex justify-content-center pb-3"}>
      <Col md={10} xs={11} className={"divider"} />
    </Container>
    {SectionList.map((section, index) => <Section {...section} key={"Section-" + index} />)}
  </div>);
}

export default OurMission;