import React from 'react';

import { assets } from "../../../assets/config"
const { baseUrl } = assets

export const Media = (props) => {
    const { url, className, children } = props || {};
    return (<div style={{backgroundImage: `url(${baseUrl + url})`}} className = { className } >
        {children}
    </div>)
}