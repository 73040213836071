import React from "react";
import { Media } from "./Media"

import { assets } from "../../../assets/config"
import { Col, Container } from "react-bootstrap";
const { common: { haknova_ocean_banner }, about: { haknova_ruth_photo, haknova_ruth_signature_photo } } = assets

const About = (props) => {
  return(<div>
    <div className={"anchor-margin"} id={props.id}/>
    <Media url={haknova_ocean_banner} className={"w-100 ocean-banner"}>
      <div className={"text-white text-center mx-5 ocean-banner-text"}>ABOUT</div>
    </Media>
    <div className={"d-flex flex-wrap"}>
      <Col md={6} className={"ruth-text d-flex align-items-center justify-content-center"}>
        <Col lg={8} md={10} xs={11}>
          <div className={""}>The Halo Graham Foundation for the Missing at Sea was founded in 1980 by Ruth Graham, who named the organization in honor of the daughter she lost in a riptide in 1961. Until her death at 82, Mrs. Graham worked tirelessly to help those in need and to console survivors in their time of crisis.</div>
          <Container fluid className={"d-flex divider justify-content-center my-4"}/>
          <div className={"ruth-quote  pb-2"}>“I understand how devastating it is to lose someone to the depths. With no casket over which to mourn, there is no end to the hope, however painful it may be, that one day you will be reunited with the one you love. The doubt and blame can tear a family apart, and the lack of closure can have lifelong consequences for those who live on. Now that I find myself in a position to use my resources to help others who are suffering as I have, I hope to one day prevent anyone from knowing the pain that I have known.”</div>
          <Media url={haknova_ruth_signature_photo} className={"w-100 haknova-ruth-signature-photo"} />
          <Container fluid className={"d-flex divider justify-content-center my-4"}/>
          <div className={""}>Since 2005, the Halo Graham Foundation has been supported by a trust established by Mrs. Graham’s estate and by donations from members across the world. The Halo Graham Foundation for the Missing at Sea continues to strive to realize Mrs. Graham’s vision of a world where no one lost at sea is lost forever.</div>
          <div>{props?.content || ""}</div>
        </Col>
      </Col>
      <Col md={6} xs={12} className={"p-0"}>
        <Media url={haknova_ruth_photo} className={"w-100 haknova-ruth-photo"} />
      </Col>
    </div>
  </div>);
}

export default About